export default [
  {
    title: 'Dashboard',
    route: 'home',
    meta: { role: '*' },
    icon: 'HomeIcon',
  },
  {
    title: 'Atelier',
    icon: 'PackageIcon',
    meta: { role: 'MANAGER' },
    children: [
      {
        title: 'Fabrication',
        route: 'productions',
        meta: { role: 'MANAGER' },
        icon: 'PackageIcon',
      },
      {
        title: 'Prévisionnel',
        route: 'productionForecast',
        meta: { role: 'MANAGER' },
        icon: 'ThermometerIcon',
      },
    ],
  },
  {
    title: 'Clients',
    route: 'clients',
    meta: { role: 'ADMINISTRATOR' },
    icon: 'UsersIcon',
  },
  {
    title: 'Shop',
    icon: 'ShoppingBagIcon',
    meta: { role: 'ADMINISTRATOR' },
    children: [
      {
        title: 'Commandes',
        route: 'orders',
        meta: { role: 'ADMINISTRATOR' },
        icon: 'ShoppingCartIcon',
      },
      {
        title: 'Abonnements',
        route: 'subscriptions',
        meta: { role: 'ADMINISTRATOR' },
        icon: 'CalendarIcon',
      },
      {
        title: 'Formules',
        route: 'subscriptions_plans',
        meta: { role: 'ADMINISTRATOR' },
        icon: 'RefreshCcwIcon',
      },
      // {
      //   title: 'Coupons',
      //   route: 'orders_coupons',
      //   meta: { role: 'ADMINISTRATOR' },
      //   icon: 'PercentIcon',
      // },
      {
        title: 'Coupons',
        route: 'coupons',
        meta: { role: 'ADMINISTRATOR' },
        icon: 'PercentIcon',
      },
      {
        title: 'Paiements',
        route: 'payments',
        meta: { role: 'ADMINISTRATOR' },
        icon: 'CreditCardIcon',
      },
      {
        title: 'Avis clients',
        route: 'reviews',
        meta: { role: 'ADMINISTRATOR' },
        icon: 'StarIcon',
      },
      {
        title: 'Évaluations clients',
        route: 'evaluations',
        meta: { role: 'ADMINISTRATOR' },
        icon: 'StarIcon',
      },
    ],
  },
  {
    title: 'Au menu',
    route: 'selections',
    meta: { role: 'ADMINISTRATOR' },
    icon: 'CalendarIcon',
  },
  {
    title: 'Recettes',
    icon: 'ListIcon',
    meta: { role: 'MANAGER' },
    children: [
      {
        title: 'Recettes',
        route: 'recipes',
        meta: { role: 'MANAGER' },
        icon: 'ListIcon',
      },
      {
        title: 'Ingrédients',
        route: 'ingredients',
        meta: { role: 'MANAGER' },
        icon: 'ListIcon',
      },
    ],
  },
  {
    title: 'Blogs',
    icon: 'FileTextIcon',
    meta: { role: 'CONTENT_EDITOR' },
    children: [
      {
        title: 'Blogs',
        route: 'stories',
        meta: { role: 'CONTENT_EDITOR' },
        icon: 'FileTextIcon',
      },
    ],
  },
  {
    title: 'Exports',
    icon: 'DownloadIcon',
    meta: { role: 'CONTENT_EDITOR' },
    route: 'exports',
  },
]
