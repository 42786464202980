<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <navbar-search class="" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto d-none d-md-flex">
      <b-nav-item
        :href="`${themeConfig.apiUrl}/v1/private/api-documentation/`"
        target="_blank"
      >
        Private API <font-awesome-icon icon="database" />
      </b-nav-item>
      <b-nav-item
        :href="`${themeConfig.apiUrl}/v1/api-documentation/`"
        target="_blank"
      >
        Public API <font-awesome-icon icon="database" />
      </b-nav-item>
      <b-nav-item
        :href="
          themeConfig.frontendUrl.replace(
            'frontend.leonetmarcel.docker',
            'localhost:3000'
          )
        "
        target="_blank"
        class="pr-1"
      >
        Site <font-awesome-icon icon="external-link-alt" />
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ $store.getters['user/profile'].screenname }}
            </p>
            <span class="user-status">
              {{ $t($store.getters['user/profile'].role) }}
            </span>
          </div>
          <b-avatar
            size="40"
            variant="secondary"
            badge
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click.prevent="logout()"
        >
          <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { $themeConfig } from '@themeConfig'
import {
  BLink,
  BNavbarNav,
  BNavItem,
  BNavItemDropdown,
  BDropdownItem,
  BAvatar,
} from 'bootstrap-vue'
import NavbarSearch from '@/components/NavbarSearch.vue'
// import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BAvatar,
    BNavItem,
    NavbarSearch,
    // Navbar Components
    // DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      themeConfig: $themeConfig,
    }
  },
  methods: {
    async logout() {
      try {
        await this.$http.post('/auth/signout', {})
      } catch (e) {
        // console.log(e)
      }
      this.$router.push('/login')
    },
  },
}
</script>
