<template>
  <div>
    <b-form @submit.prevent="$router.push(`/users?term=${query}&role=`)">
      <b-form-group label-for="input-label">
        <b-input-group>
          <b-input-group-prepend is-text>
            <font-awesome-icon icon="search" />
          </b-input-group-prepend>
          <b-form-input v-model="query" placeholder="Rechercher un client" />
        </b-input-group>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue'

export default {
  name: 'NavbarSearch',
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
  },
  props: {},
  data() {
    return {
      query: null,
    }
  },
  methods: {},
}
</script>
