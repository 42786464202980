import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: 'http://api.leonetmarcel.docker/v1/private',
  withCredentials: true,
})

// Request Interceptor
axiosIns.interceptors.request.use(
  (config) => {
    // Get token from localStorage
    const accessToken = localStorage.getItem('accessToken')

    // If token is present add it to request's Authorization Header
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`
    }
    return config
  },
  (error) => Promise.reject(error)
)

axiosIns.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const {
      response: { status },
    } = error
    const parsedUrl = new URL(window.location.href)
    if (status === 401 && parsedUrl.pathname !== '/login') {
      // console.log(error)
      document.location = '/login'
    }
    return Promise.reject(error)
  }
)

Vue.prototype.$http = axiosIns

export default axiosIns
